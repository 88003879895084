/* My custom code */
.hover-1:hover{
    opacity: 0.8 !important;
}
.tooltip-product
{
    width: 100px;
    white-space : normal;
}
.custom-eye-icon{
    color: #161616;
    opacity: 0.7;
}
.custom-eye-icon:hover{
    color: #00BEC9;
}
.custom-edit-icon{
    color: #161616;
    opacity: 0.7;
}
.custom-edit-icon:hover{
    color: #f5c014;
}
.custom-delete-icon{
    color: #161616;
    opacity: 0.7;
}
.custom-delete-icon:hover{
    color: #e20000;
}
.custom-color-primary{
    color: #00BEC9;
}
.custom-color-danger{
    color: #ee0000;
    font-size: 0.8rem !important;
}
.custom-error-msg{
    color: #ee0000;
    font-size: 70%;
    text-align: center;
}
.custom-bg-primary{
    background: #00BEC9;
}
.custom-button-primary{
    color: #00BEC9;
    border-radius: 5px;
    border: 2px solid #00BEC9;
    background-color: white;
    box-shadow: #aaa 0px 0px 7px;
}
.custom-button-secondary{
    color: white;
    border-radius: 5px;
    border: none;
    /* border: 2px solid white; */
    background-color: #00BEC9;
    box-shadow: #aaa 0px 0px 7px;
}
.custom-mouse-clickable{
    cursor: pointer;
}
.custom-separator-bar-secondary{
    /* position: absolute;
    top: 7px;
    left: 65px;
    z-index: 200; */
    width: 0px;
    border: 1.2px solid #00BEC9;
    height: 35px;
}
.custom-separator-bar-primary{
    /* position: absolute;
    top: 7px;
    left: 70px;
    z-index: 200; */
    width: 0px;
    border: 1.2px solid #fff;
    height: 35px;
}
.custom-table-column{
    opacity: 0.9;
    background: linear-gradient(0deg,#161616, #161616, #111111);
}
.custom-height-max{
    min-height: 100vh;
}
.custom-card-shadow{
    box-shadow: 0px 0px 5px gray
}

/* For the tables */
.custom-bt-container{
    height: auto;
    display: block;
}
.opacity-1{
    opacity: 0.2 !important;
}

p{
    line-height: 28px;
}

@media screen and (min-width: 1100px) {
    .custom-bt-container{
        overflow-x: visible
    }
}

@media screen and (max-width: 500px) {
    p{
        font-size: 14px !important;
        padding: 0 !important;
    }
    .justify-content-mobile{
        justify-content: space-between !important;
    }
}