.login-top-bar{
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.login-page{
    min-height: 100vh;
    background: linear-gradient(180deg, #008FA0 0%, #00BEC9 100%);

}
.login-hr{
    width: 20%;
    margin: auto !important;
    margin-top: -20px !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 50px;
}
.image-fewnu-login{
    width: 250px;
}
.login-input{
    border-radius: 7px;
    border: none;
    height: 42.09px;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
}
.login-input:focus{
    margin-top: 20px;
    width: 100%;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    /* border: #00BEC9 2px; */
}
.login-input::placeholder{
    color: white;
}
.login-btn{
    width: 100%;
    color: #00BEC9;
    font-weight: bold;
    border: none;
    
    height: 42.09px;
    background: #FFFFFF;
    box-shadow: 0px 3.74131px 3.74131px rgba(0, 0, 0, 0.3);
    border-radius: 9.35329px;
}

@media screen and (max-width: 500px) {
    .login-hr{
        width: 45%;
    }
}