:root {
    --loading-grey: #ededed;
  }
  
/* ************* services component ************** */
.btn-add{
    font-weight: 500 !important;
    background-color: #00BEC9;
    color: white;
    border: none;
    border-radius: 8px !important;
    box-shadow: 0px 1px 1px rgba(10, 12, 15, 0.25);
}
.empty--message{

}
thead, tr{
    width: 100% !important;
}
/* ***************** search **************** */
input{
    padding: 0;
    margin: 0;
}
.errormessage {
    visibility: hidden;
  }
  
  [aria-invalid="true"] {
    outline: 2px solid red;
  }
  
  [aria-invalid="true"] ~ .errormessage {
    visibility: visible;
  }
ul, li{
    list-style: none;
}
a{
    color: black;
    font-weight: 500;
    text-decoration: none;
}
a:hover{
    color: #00BEC9;
}
a.active{
    border-bottom: 5px solid #00BEC9;
}
.input-search-container{
    color: #00BEC9;
    height: 40px;
    box-sizing: border-box;
    border-radius: 13.7143px;
}
.icon-search{
    position: absolute;
    left: 15px;
    top: 13px;
    /* transform: translate(50%, 50%); */
}
.input-search{
    display: inline-block;
    width: 80%;
    background-color: rgba(0,0,0,0);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem !important;
    border: 1.37143px solid #00BEC9;
    border-radius: 8px;
}
.input-search-container .input-search{
    outline: none;
}

/* ***************** custom style *************** */
.app-padding{
    padding-left:  1.5rem !important;
    padding-right:  1.5rem !important;
  }

/* Font size */
.fs-70{
    font-size: 70%;
}
.fs-80{
    font-size: 80%;
}
.fs-90{
    font-size: 90%;
}

.action-btn:hover{
    background-color: #98ebef !important;
}

/* ************************ articles ******************** */
/* .img-article:nth-child(even){
    padding-right: 1rem !important;
} */
.facture-articles h5{
    font-size: 12.5px !important;
}
.articles-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(140px,1fr));
    gap: 15px;
    max-block-size: 61vh;
    overflow-y: auto;
    padding-bottom: 2px;
}
.add-to-articles{
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, .8);
    color: white;
    display: none;
    transition: all 500ms ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.img-article:hover .add-to-articles{
    display: block;
}
.truncate-title {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
.text-gris{
    color: #8c98a4!important;
    font-size: 12px;
}

/* *********************Start Modal************************* */
.modal-container {
    width: 100vw;
    height: 100vh;
    background-color: #0000004d;
    overflow-y: auto;
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 9990;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container>.modal-children {
    min-width: 300px;
    max-width: 650px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin: 0 10px;
    margin-top: -20%;
}

.modal-container>.modal-children>.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.modal-container>.modal-children>.modal-header>.modal-close-icon {
    /* background-color: #0000004d; */
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 5px;
    color: #00BEC9;
    cursor: pointer;
}

/* *********************End Modal************************* */


/* .action-btn:hover{
    background-color: red !important;
} */

@media screen and (max-width) {
    
}

@media screen and (max-width: 766px) {
    .input-search{
        width: 100%;
    }
    .empty--message{
        font-size: 1rem;
    }
    .app-padding{
        padding-left:  0.8rem !important;
        padding-right:  0.8rem !important;
      }
}