
/* .react-bootstrap-table{
} */
table{
    min-width: 1100px;
}
td{
    padding: 1rem !important;
    clear: both;
    /* display: inline-block; */
    overflow: hidden;
    white-space: nowrap;
}
th{
    width: 100px !important;
}
/* .facture-a-montrer td{
    padding: 0 !important;
} */
/* #facture-a-imprimer{
    width: 100%;
} */
.table-a-imprimer table{
    min-width: 500px !important;
}
.react-bootstrap-table{
    overflow: auto !important;
}
.product table{
    min-width: 100px !important;
}
.product table td{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 12px !important;
    vertical-align: middle;
    /* display: flex; */
    /* align-items: center; */
}
.table-a-imprimer .react-bootstrap-table{
    overflow: visible;
}
.react-bootstrap-table-pagination div{
    display: flex;
    justify-content: flex-start;
}
.react-bootstrap-table-pagination-list{
    display: flex;
    justify-content: flex-end !important;
}
li.page-item .page-link{
    color: rgba(20,20,20,0.9);
    padding: 0.5rem 1rem !important;
}
li.page-item.active .page-link{
    background-color: rgba(20,20,20,0.9);
    padding: 0.5rem 1rem;
    border-color: #aaa;
    color: white;
}
button#pageDropDown{
    background-color: rgba(20,20,20,0.9);
    padding: 0.5rem 1rem !important;
}

.dropdown-menu{
    padding: 0.5rem;
}

.react-bootstrap-table-pagination{
    margin-top: 1rem !important;
}

.dropdown-item a{
    padding: 0.5rem !important;
}
.react-bootstrap-table-pagination .dropdown-menu li a:hover{
    background-color: #96e3e8;
}

.react-bootstrap-table-pagination .dropdown-menu li a{
    color: #00BEC9;
    text-decoration: none;
    font-weight: bold;
    display: block;
    padding: 0.5rem;
    width: 100%;
}

.products-table .react-bootstrap-table{
    min-height: 400px;
}

/* ************ scroll bar ********** */
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(80, 74, 74);
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(69, 67, 67,.5);
    border-radius: 10px;
  }