
.btn-add-client{
    color: white;
    height: 40px;
    background: #00BEC9;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}
.btn-add-client:hover{
    background-color: white;
    color: #00BEC9;
}
