.navbar-top-tool{
    background: #161616;
    opacity: 0.9;
}
.navbar-bottom-tool{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.navbar_img{
    /* margin-left: -10px !important; */
}

div.navbar-bottom-tool ul{
    
}
div.navbar-bottom-tool ul li{
    display: inline;
    margin-top: 7px;
    margin-bottom: 7px;
}
div.navbar-bottom-tool ul li:hover{
    color: #00BEC9;
}
div.navbar-bottom-tool ul li.active span.active{
    font-weight: bold;
    padding-bottom: 3px;
    border-bottom: 3px #00BEC9 solid;
}
