
.nav-menu{
  width: 70vw;
}
.sidebar-ul{
  padding: 1rem;
}
.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(55, 55, 55, 0.5);
}

.sidebar-text{
  display: inline-block;
  width: 100%;
  /* margin-top: 1rem !important; */
}

.sidebar_item{
  margin-top: 1rem !important;
  text-align: left;
  width: 100%;
}
.sidebar_item a{
  color: white;
  width: 100%;

}
.sidebar_item a.active{
  /* background-color: white; */
}

@media screen and (max-width: 500px) {
  .sidebar_icon{
    font-size: 16px !important;
  }
  .sidebar-text{
    font-size: 14px;
  }
}