.custom-table-column, td  p, td input, th{
    padding: 0.5rem !important;
}

.btn-add-client{
    display: flex;
    justify-content: space-between;
    color: white;
    /* height: 40px; */
    background: #00BEC9;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.8rem !important;
}
.btn-add-client:hover{
    background-color: white;
    color: #00BEC9;
}

.group-btn{
    display: flex !important;
    justify-content: flex-end;
}

#pageDropDown{
    padding: 0.3rem !important;
}

a.page-link{
    display: inline-block;
    padding: 0.3rem 0.5rem !important;
}

@media screen and (max-width: 500px) {
    .group-btn{
        justify-content: space-between;
    }

    .btn-add-client{
        font-size: 14px;
    }

    .service-btn-icon{
        font-size: 0.5rem !important;
    }

    .custom-table-column, td  p, td, td input, th{
        padding: 0.3rem !important;
        font-size: 15px;
    }
}