.add-client-input{
    width: 100%;
}

.add-client-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(55, 55, 55, 0.5);
    /* width: 900px !important; */
  }


@media screen and (max-width: 600px) {
    label{
        font-size: 14px !important;
    }
    .ReactModal__Content {
        width: 95% !important;
        height: 98vh !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    } 
}
/* .ReactModal__Content--after-open */