
.movie--isloading {
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
}

.movie--isloading .loading-image {
  height: 90px;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
          animation: shine-loading-image 2s infinite ease-out;
}

.movie--isloading .loading-content {
  height: 50px;
  background: #fff;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
        }
        
        .movie--isloading .loading-content .loading-text-container {
          -ms-flex-preferred-size: 100%;
          margin-top: 20px;
      flex-basis: 100%;
}

.movie--isloading .loading-content .loading-main-text {
  height: 5px;
  width: 100%;
  background: #ececec;
  background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
          animation: shine-loading-container-items 2s infinite ease-out;
}


@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}


@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}

@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}




.skeleton-row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10px;
  gap: 5px;
}

.skeleton-table {
  background: #e1e1e1;
  border-radius: 4px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.skeleton-table::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}